<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Balance the following nuclear equation:</p>

      <p class="mb-5 pl-12" style="font-size: 1.5em">
        <chemical-latex content="^{81}_{35}Br + ^\text{A}_\text{Z}X -> ^{98}_{43}Tc" />
      </p>

      <calculation-input
        v-model="inputs.inputA"
        prepend-text="$\text{A}:$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.inputZ"
        prepend-text="$\text{Z}:$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <chemical-symbol-input
        v-model="inputs.inputX"
        prepend-text="$\text{X}:$"
        class="mb-0"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';

export default {
  name: 'ChemCPP1220A4Q3',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
    ChemicalSymbolInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputZ: null,
        inputX: null,
      },
    };
  },
};
</script>
